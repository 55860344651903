import React, { useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import useRead from "../../hooks/useRead";
import PrimaryLoader from "../../components/common/PrimaryLoader";
import { numberWithComma } from "../../utility";
import Layout from "../../components/layout/Layout";
import useGet from "../../hooks/read/useGet";
import toast from "react-hot-toast";
import useDownloadGet from "../../hooks/useDownloadGet";
import Pagination from "../../components/pagination";
import Loader from "../../components/Loader";
import useDownloadFile from "../../hooks/useDownloadFile";

const SingleBonus = () => {
  const { id, username } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const bonus_id = id;
  const navigate = useNavigate();
  const [bonusId, setBonusId] = useState(null);
  const [filename, setFilename] = useState("");
  const [loadingStates, setLoadingStates] = useState({}); // Track loading states for each bonus_id
  const [loadingConsolidateStates, setLoadingConsolidateStates] = useState({}); // Track loading states for each bonus_id
  const { reportDownload } = useDownloadFile({
    url: 'bonus_report',
    filename,
    onError: () => toast.error('There was an error while downloading the file'),
  });
  const { reportDownload:consolidatedreport } = useDownloadFile({
    url: 'bonus_report_monthly_count',
    filename,
    onError: () => toast.error('There was an error while downloading the file'),
  });
  const handleDownloadReport= (bonus_id,from_date, to_date) => async (e) => {
    e.preventDefault();
    setLoadingStates((prev) => ({ ...prev, [bonus_id]: true }));
    const fromDateString = from_date
        ? new Date(from_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
      const toDateString = to_date
        ? new Date(to_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
  

      // Set the filename dynamically
      const reportFilename = `Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
      setFilename(reportFilename); // Update the filename state
    try {
      await reportDownload.mutateAsync({ bonus_id}); // Pass report data including bonus_id
      toast.success("File Downloaded Successfully");
      setLoadingStates((prev) => ({ ...prev, [bonus_id]: false }));
    } catch (error) {
      console.error(error);
      setLoadingStates((prev) => ({ ...prev, [bonus_id]: false }));
    }
  };
  const handleDownloadConsolidateReport= (bonus_id,from_date, to_date) => async (e) => {
    e.preventDefault();
    setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: true }));

    const fromDateString = from_date
        ? new Date(from_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
      const toDateString = to_date
        ? new Date(to_date * 1000).toLocaleDateString("en-GB")
        : "N/A";
  
      // Set the filename dynamically
      const reportFilename = ` Consolidated Bonus Report for ${fromDateString} - ${toDateString}.xlsx`;
      setFilename(reportFilename); // Update the filename state
    try {
      await consolidatedreport.mutateAsync({ bonus_id}); // Pass report data including bonus_id
      toast.success("File Downloaded Successfully");
      setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: false }));
    } catch (error) {
      console.error(error);
      setLoadingConsolidateStates((prev) => ({ ...prev, [bonus_id]: false }));
    }
  };
  const initialEmployeeState = {
    page_number: searchParams.get("page_number") || 0,
    limit: searchParams.get("limit") || 10,
    search_query: searchParams.get("search_query") || "",
    bonus_id: id,
  };
  const handlePageClick = (val) => {
    let data = {
      ...paramsObject,
      page_number: val.selected,
    };
    setGetListParams(data);
    setSearchParams(data);
  };
  const { list, paramsObject, setGetListParams } = useGet({
    url: "fetch_single_bonus",
    initialData: initialEmployeeState,
    onSuccess: () => {
      // scrollToTop()
      // toast.success('Employees Fetched Successfully')
    },
    onError: () => {
      toast.error(
        "There Was An Error While Fetching The Data Please Try Again Later"
      );
    },
  });
  // const handleNavigateToEmployeeDetail = () => {
  //     navigate(`/employee-salary/${id}`, {
  //         state: list.data?.data?.[0]
  //     })
  // }
  console.log(list?.data?.date);
  const from_date = list?.data?.date?.from_date;
  const to_date = list?.data?.date?.to_date;
  return (
    <Layout>
      <>
        {list.isLoading && (
          <div className=" fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-[#ffffffc7]">
            <PrimaryLoader />
          </div>
        )}
        <div className=" max-w-7xl px-8 py-8">
         <div className="py-4 text-start">
                        <Link to={"/bonus"} className=" bg-[color:var(--color1)] text-white rounded py-2 px-5">Back</Link>
                    </div>
          <div>
            <div className="flex items-center space-x-3">
              <h1 className="font-semibold text-xl">Bonus Detail</h1>
            </div>
            <div className="flex items-center space-x-16 mt-5">
              <div className=" space-y-2">
                <p className="text-gray-500 text-sm">Bonus Title</p>
                <h2 className="text-gray-800 font-bold text-lg">
                  {list?.data?.date?.title}
                </h2>
              </div>
              <div className=" space-y-2">
                <p className="text-gray-500 text-sm">From Date</p>
                <h2 className="text-gray-800 font-bold text-lg">
                  {" "}
                  {list?.data?.date?.from_date
                    ? new Date(
                        list.data.date.from_date * 1000
                      ).toLocaleDateString()
                    : "No Date Available"}
                </h2>
              </div>
              <div className=" space-y-2">
                <p className="text-gray-500 text-sm">To Date</p>
                <h2 className="text-gray-800 font-bold text-lg">
                  {" "}
                  {list?.data?.date?.to_date
                    ? new Date(
                        list.data.date.to_date * 1000
                      ).toLocaleDateString()
                    : "No Date Available"}
                </h2>
              </div>
              <div className=" space-y-2">
                <p className="text-gray-500 text-sm">Total no. of days</p>
                <h2 className="text-gray-800 font-bold text-lg">
                  {list?.data?.date?.total_no_of_days}
                </h2>
              </div>
              <div
                className=" flex justify-center space-x-1"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  onClick={handleDownloadReport(bonus_id, from_date, to_date)}
                  className="bg-[color:var(--color1)] text-white rounded px-4 py-4 flex items-center"
                  disabled={loadingStates[bonus_id] || reportDownload.isLoading}
                >
                  {loadingStates[bonus_id] ? (
                    <span className=" max-h-3"> <Loader/></span> // Add your loader styling here
                  ) : (
                    "Download Report"
                  )}
                </button>
              </div>
              <div
                              className=" flex justify-center space-x-1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                onClick={handleDownloadConsolidateReport(
                                  bonus_id,
                                  from_date,
                                  to_date
                                )}
                                className="bg-[color:var(--color1)] text-white rounded px-4 py-4 flex items-center"
                                disabled={loadingConsolidateStates[bonus_id] || consolidatedreport.isLoading}
                              >
                                {loadingConsolidateStates[bonus_id] ? (
                            <span className="max-h-3"> <Loader/></span> // Add your loader styling here
                          ) : (
                            "Download Consolidate Report"
                          )}
                              </button>
                            </div>
            </div>
          </div>

          <div class="max-w-full overflow-x-auto px-4 mt-8">
            <table class="table-auto w-full">
              <thead>
                <tr class=" bg-[color:var(--color1)] text-center">
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">
                    Employee Name
                  </th>
                  {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Mobile Number</th> */}
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                    Base Salary
                  </th>
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                    Gross Salary
                  </th>
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                    Bonus Amount
                  </th>
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                   Eligible Bonus Amount
                  </th>
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                    No. of Days
                  </th>
                  <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">
                    Status
                  </th>

                  {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">
                        Action
                      </th> */}
                </tr>
              </thead>
              <tbody>
                {list.data?.date?.user_data?.map((data, index) => {
                  const {
                    employee_name,
                    bonus_id,
                    base_salary,
                    gross_salary,
                    bonus_amount,
                    employee_count,
                    is_hibernate,
                    from_date,
                    to_date,
                    email,
                    eligible_bonus_amount,
                    id,
                    no_of_days,
                    status,
                    type_of_employee,
                  } = data;
                  return (
                    <tr
                      // onClick={() => handleNavigateToBonusDetail(bonus_id)}
                      key={index}
                      className="bg-gray-200 hover:bg-gray-100 cursor-pointer"
                    >
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-l border-[#E8E8E8]">
                        {employee_name}
                      </td>
                      {/* <td class=" text-center text-dark font-medium text-sm py-3 px-2 bg-white border-b border-[#E8E8E8]">{phone}</td> */}
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {base_salary}
                      </td>
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {gross_salary}
                      </td>
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {bonus_amount}
                      </td>
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {eligible_bonus_amount}
                      </td>
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {no_of_days}
                      </td>
                      <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-[#E8E8E8]">
                        {status}
                      </td>

                      {/* <td class="text-center text-dark font-medium text-sm py-3 px-2  border-b border-r border-[#E8E8E8]">
                            <div
                              className=" flex justify-center space-x-1"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                onClick={handleDownloadReport(
                                  bonus_id,
                                  from_date,
                                  to_date
                                )}
                                className="bg-[color:var(--color1)] text-white rounded px-4 py-4 flex items-center"
                                disabled={loadingStates[bonus_id] || reportDownload.isLoading}
                              >
                                {loadingStates[bonus_id] ? (
                            <span className="loader max-h-3"></span> // Add your loader styling here
                          ) : (
                            "Download Report"
                          )}
                              </button>
                            </div>
                          </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 mt-5">
              {/* {console.log(list?.data?.total_count)}
              <Pagination
                currentPage={+paramsObject?.page_no}
                lengthofItems={list?.data?.total_count}
                limit={paramsObject?.per_page}
                onPageChange={handlePageClick}
              /> */}
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default SingleBonus;
