import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';


import ProtectedRoutes from './Routes/ProtectedRoutes';
import EmployeeDetails from './pages/employee_details';
import AddEmployeeDetails from './pages/employee_details/AddEmployeeDetails';

import LeaveApplication from './pages/leave_application';
import LeaveApplicationForm from './pages/leave_application/LeaveApplicationForm';

import Appointment from './pages/appointment_letter';
import AppointmentLetter from './pages/appointment_letter/AppointmentLetter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LeaveApplied from './pages/leaves_applied';
import LeavesApplied from './pages/leaves_applied/LeavesApplied';
import EditEmployee from './pages/employee_details/EditEmployee';
import Department from './pages/department';
import LeaveDetail from './pages/employee_details/LeaveDetail';
import Salary from './pages/Salary';
import Holidays from './pages/holidays';
import Attendance from './pages/attendance';
import ViewAttendance from './pages/attendance/ViewAttendance';
import PageNotFound from './pages/errors/PageNotFound';
import Register from './pages/auth/Register';
import SalaryLogs from './pages/Salary/SalaryLogs';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import EditLeaves from './pages/employee_details/EditLeaves';
import ResignedEmployeeDetails from './pages/employee_details/ResignedEmployeeDetails';
import Shifts from './pages/shifts';
import ShiftMapping from './pages/shifts/ShiftMapping';
import ShiftRotation from './pages/shifts/ShiftRotation';
import ShiftsSettings from './pages/shifts/settings';
import 'react-toastify/dist/ReactToastify.css';
import 'react-select-search/style.css'
import SingleEmployeeDetail from './pages/employee_details/SingleEmployeeDetail';
import EmployeeSalaryDetails from './pages/Salary/EmployeeSalaryDetails';
import SingleEmployeeSalary from './pages/Salary/EmployeeSalaryDetails/SingleEmployeeSalary';
import AbscondedEmployee from './pages/employee_details/AbscondedEmployee';
import User from './pages/user';
import BaseSalary from './pages/Salary/BaseSalary';
import ProvidentFund from './pages/Salary/ProvidentFund';
import Esic from './pages/Salary/ESIC';
import ProfessionalTax from './pages/Salary/PT';
import Loan from './pages/Salary/Loan';
import AdvanceAndBonus from './pages/Salary/AdvanceAndBonus';
import WebCheckin from './pages/webCheckin';
import SingleResignedEmployeeDetails from './pages/employee_details/SingleResignedEmployeeDetails';
import { Toaster } from 'react-hot-toast';
import Form16 from './pages/form16/Form16';
import Form16Input from './pages/form16/Form16Input';
import HibernationListing from './pages/employee_details/HibernationListing';
import AttendanceReport from './pages/attendance/AttendanceReport';
import BonusListing from './pages/bonus/BonusListing';
import AddBonus from './pages/bonus/AddBonus';
import SingleBonus from './pages/bonus/SingleBonus';



const queryClient = new QueryClient()
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Toaster/>
        <Router>
          <Routes>
            <Route path='/login' exact element={<Login />} />
            <Route path='/register-company' exact element={<Register />} />
            <Route path='/' exact element={<ProtectedRoutes accessTo={'all'}><Dashboard /></ProtectedRoutes>} />
            {/* <Route path='/forgot-password' exact element={<ForgotPassword />} /> */}
            {/* <Route path='/reset-password' exact element={<ResetPassword />} /> */}
            <Route path='/attendance' exact element={<ProtectedRoutes accessTo={['company']}><Attendance /></ProtectedRoutes>} />
            <Route path='/bonus' exact element={<ProtectedRoutes accessTo={['company']}><BonusListing/></ProtectedRoutes>} />
            <Route path='/attendance-report' exact element={<ProtectedRoutes accessTo={['company']}><AttendanceReport/></ProtectedRoutes>} />

            <Route path='/employe-details' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EmployeeDetails /></ProtectedRoutes>} />
            {/* <Route path='/employe-details/:id/:username' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleEmployeeDetail /></ProtectedRoutes>} /> */}
            <Route path='/employe-details/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleEmployeeDetail /></ProtectedRoutes>} />
            {/* <Route path='/employee-salary-details/:id/:username' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleEmployeeSalary /></ProtectedRoutes>} /> */}
            <Route path='/employee-salary-details/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleEmployeeSalary /></ProtectedRoutes>} />
            <Route path='/single-bonus-details/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleBonus /></ProtectedRoutes>} />
            <Route path='/absconded' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><AbscondedEmployee /></ProtectedRoutes>} />
            <Route path='/hibernation' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><HibernationListing /></ProtectedRoutes>} />
            <Route path='/resigned-employee-details' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ResignedEmployeeDetails /></ProtectedRoutes>} />
            <Route path='/resigned-employee-details/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SingleResignedEmployeeDetails /></ProtectedRoutes>} />
            <Route path='/salary' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><BaseSalary /></ProtectedRoutes>} />
            <Route path='/salary/logs' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SalaryLogs /></ProtectedRoutes>} />
            <Route path='/form16/logs' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Form16Input /></ProtectedRoutes>} />
            {/* <Route path='/employee-salary/:id/:username' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EmployeeSalaryDetails /></ProtectedRoutes>} /> */}
            <Route path='/employee-salary/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EmployeeSalaryDetails /></ProtectedRoutes>} />
            <Route path='/appointment' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Appointment /></ProtectedRoutes>} />
            <Route path='/leave-applied' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><LeaveApplied /></ProtectedRoutes>} />
            <Route path='/public-holidays' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Holidays /></ProtectedRoutes>} />
            <Route path='/add-employe-details' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><AddEmployeeDetails /></ProtectedRoutes>} />
            <Route path='/add-bonus' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><AddBonus/></ProtectedRoutes>} />
            <Route path='/edit-employe-details/:userType/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EditEmployee /></ProtectedRoutes>} />

            <Route path='/leave-application' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplication /></ProtectedRoutes>} />
            <Route path='/leaves' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveDetail /></ProtectedRoutes>} />

            <Route path='/shifts' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Shifts /></ProtectedRoutes>} />
            <Route path='/employee-shift-mapping' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ShiftMapping /></ProtectedRoutes>} />
            <Route path='/shift-rotation' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ShiftRotation /></ProtectedRoutes>} />
            <Route path='/shift-settings' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ShiftsSettings /></ProtectedRoutes>} />

            <Route path='/leaves/:id' exact element={<ProtectedRoutes accessTo={'all'}><LeaveDetail /></ProtectedRoutes>} />
            <Route path='/edit-leaves/:leaveId' exact element={<ProtectedRoutes accessTo={'all'}><EditLeaves /></ProtectedRoutes>} />
            <Route path='/add-leave-details' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplicationForm /></ProtectedRoutes>} />
            <Route path='/appointment-letter/:id' exact element={<ProtectedRoutes accessTo={'all'}><AppointmentLetter /></ProtectedRoutes>} />
            <Route path='/open-leave' exact element={<ProtectedRoutes accessTo={'all'}><LeavesApplied /></ProtectedRoutes>} />
            <Route path='/department' exact element={<ProtectedRoutes accessTo={'all'}><Department /></ProtectedRoutes>} />
            <Route path='/view-attendance/:id' exact element={<ProtectedRoutes accessTo={'all'}><ViewAttendance /></ProtectedRoutes>} />
            <Route path='/form16' exact element={<ProtectedRoutes accessTo={'all'}><Form16/></ProtectedRoutes>} />
            
            
            <Route path='/user' exact element={<ProtectedRoutes accessTo={'employee'}><User /></ProtectedRoutes>} />


            {/* SALARY */}
            <Route path='/provident-fund' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ProvidentFund /></ProtectedRoutes>} />
            <Route path='/esic' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Esic /></ProtectedRoutes>} />
            <Route path='/professional-tax' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><ProfessionalTax /></ProtectedRoutes>} />
            <Route path='/loans' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Loan /></ProtectedRoutes>} />
            <Route path='/advance-and-bonus' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><AdvanceAndBonus /></ProtectedRoutes>} />
            
            
            
            <Route path='/web-checkin' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><WebCheckin /></ProtectedRoutes>} />



            <Route path='*' exact element={<PageNotFound />} />




          </Routes>
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
