import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getIndividualEmployeeDetail, updateEmployeeDetail } from '../../api/employeeDetails'
import Layout from '../../components/layout/Layout'
import { Link, useParams,useNavigate } from "react-router-dom";
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import { generatePassword } from '../../utility';
import { Modal } from '@mui/material';
import { XIcon } from '@heroicons/react/solid';
import { resetPassword } from '../../api/auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogMessage from '../../components/DialogMessage';
import useReadWithGet from '../../hooks/read/useReadWithGetMethod';
import AddressForm from './EmployeeForm/AddressForm';
import BankDetailsForm from './EmployeeForm/BankDetailsForm';
import WorkDetails from './EmployeeForm/WorkDetails';
import PersonalDetailForm from './EmployeeForm/PersonalDetailForm';
import toast, { Toaster } from 'react-hot-toast';
let IS_REQUIRED = true
let val = [0, 0, 0]
function AddEmployeeDetails() {
    const navigate = useNavigate();
    const personalDetailRef = useRef(null)
    const addressDetailRef = useRef(null)
    const bankDetailRef = useRef(null)
    const workDetailRef = useRef(null)
    let initialDepartmentState = {
        limit: 50,
        searchQuery: '',
    }
    const { id, userType } = useParams()
    const [empCreateError, setEmpCreateError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [employeDetail, setEmployeDetail] = useState({
        date_of_leaving: '',
        date_of_resignation: '',
        statutory: {
            epf: {
                isEpf: false,
                pf_account_number: '',
                pf_uan_number: '',
                is_contribute_to_scheme: false
            },
            esi: {
                isEsi: false,
                esi_number: ''
            },
            isPt: false
        }
    })
    const [isShowingModal, setIsShowingModal] = useState(false)
    const [regeneratePasswordModal, setRegeneratePasswordModal] = useState(false)
    const handleSubmitEmployeDetail = (e) => {
        setIsLoading(true)
        e.preventDefault()
        let personalData = personalDetailRef.current.getPersonalData()
        let addressData = addressDetailRef.current.getAddressData()
        let bankData = bankDetailRef.current.getBankDetailData()
        let workData = workDetailRef.current.getWorkDetail()
        let data = {
            ...personalData,
            ...addressData,
            ...bankData,
            ...workData,
            ...employeDetail,
            id
        }
        updateEmployeeDetail(data)
            .then((res) => {
                if (res.status === 200) {
                    setIsShowingModal(true)
                }
                toast.success('Employee Updated Successfully')
                navigate(-1)
            })
            .catch((err) => {
                if (err?.response?.data?.message === "Required Fields") {
                }
                setEmpCreateError(err?.response?.data?.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    const { list } = useReadWithGet({
        url: 'employeedetails_edit',
        initialData: { id },
        onSuccess: (res) => {
            setEmployeDetail((prev) => {
                return {
                    ...prev,
                    statutory: res.data.statutory
                }
            })
        }
    })
    const [newPassword, setNewPassword] = useState('')
    const [copied, setCopied] = useState(false)
    const [isResettingPassword, setResettingPassword] = useState(false)
    const handleGeneratePassword = () => {
        var password = generatePassword({
            uppercase: true,
            lowercase: true,
            symbols: true,
            numbers: true
        })
        setCopied(false)
        setNewPassword(password)
    }

    const handleSetNewPassword = (e) => {
        setResettingPassword(true)
        e.preventDefault()
        setCopied(false)
        resetPassword(id, newPassword)
            .then((res) => {
                if (res.status === 200) {
                    setResettingPassword(false)
                    setRegeneratePasswordModal(false)
                }
            })
            .catch((err) => {
                setResettingPassword(false)
                alert("Something went wrong please try again later")
            })
    }
    const [statutoryFormVisiblity, setStatutoryFormVisiblity] = useState({
        isEpf: false,
        isEsi: false,
    })
    const handleChangeStatutoryComponent = (e) => {
        let checked = e.target.checked
        setStatutoryFormVisiblity(prev => {
            return {
                ...prev,
                [e.target.name]: checked
            }
        })
        let data = employeDetail.statutory
        if(e.target.name === 'isEpf'){
            data['epf']['isEpf'] = checked
        }else{
            data['esi']['isEsi'] = checked
        }
        setEmployeDetail({...employeDetail})
    }

    const handleChangeStatutoryForm = (e, key) => {
        let name = e.target.name
        let value = e.target.value
        let copyData = employeDetail
        copyData.statutory[key][name] = value
        setEmployeDetail({ ...employeDetail })
    }

    const handleChangeStatutoryCheckbox = (e, key) => {
        let name = e.target.name
        let value = e.target.checked
        let copyData = employeDetail
        if (key === 'isPf') {
            copyData.statutory[key][name] = value
        } else {
            copyData.statutory[name] = value
        }
        setEmployeDetail({ ...employeDetail })
    }
    const [departOption, setDepartOption] = useState([])
    useReadWithGet({
        url: "department_listing",
        initialData: initialDepartmentState,
        onSuccess: (res) => {
            let data = res.data.map(({ id, name }) => ({ value: id, name: name }))
            setDepartOption(data);
        }
    })
    const handleChangeExtras = (e) => {
        let name = e.target.name
        let value = e.target.value
        setEmployeDetail(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    return (
        <Layout>
            <Toaster/>
            {isLoading ? <div className=' w-16 h-16 mx-auto my-16'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" bg-[color:var(--color3)]">
                <div className=" container">
                    <div className=" px-4 py-4 text-start">
                        <Link to={"/employe-details"} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</Link>
                    </div>
                    <form action="" method="POST" onSubmit={handleSubmitEmployeDetail} >
                        <div className="px-6">
                            <div className="">
                                <button type='button' onClick={() => setRegeneratePasswordModal(true)} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Regenerate Password</button>
                            </div>
                            <PersonalDetailForm ref={personalDetailRef} departOption={departOption} isEdit={true} personalData={list?.data?.data} />
                            <AddressForm ref={addressDetailRef} addressData={list?.data?.data} />
                            <BankDetailsForm ref={bankDetailRef} bankDetailData={list?.data?.data} />
                            <WorkDetails ref={workDetailRef} workDetailData={list?.data?.data} />
                            <div className=" grid grid-cols-3 gap-4  personalDetail-section mt-4">
                                <div className="">
                                    <label htmlFor="Probation date" className='label'>Date Of Leaving:<span className='mandatory'>*</span></label>
                                    <input type="date" name='date_of_leaving' className='input' value={employeDetail.date_of_leaving} onChange={handleChangeExtras} />
                                </div>
                                <div className="">
                                    <label htmlFor="Probation date" className='label'>Date of Resignation:<span className='mandatory'>*</span></label>
                                    <input type="date" name='date_of_resignation' className='input' value={employeDetail.date_of_resignation} onChange={handleChangeExtras} />
                                </div>
                            </div>
                            <br />
                            <div className=''>
                                <div>
                                    <h1 className='mt-4 text-2xl font-semibold'>Statutory Components</h1>
                                    <small>Enable the necessary benefits and tax applicable for this employee.</small>
                                </div>
                                <div className=' space-y-4'>
                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                        <div className=" mt-4">
                                            <div className=' space-x-2'>
                                                <input type="checkbox" id='isEpf' name='isEpf' checked={employeDetail.statutory?.epf?.isEpf} onChange={handleChangeStatutoryComponent} />
                                                <label htmlFor='isEpf' className=' cursor-pointer'>Employees' Provident Fund</label>
                                            </div>
                                            {employeDetail.statutory.epf.isEpf &&
                                                <div className='border-b border-gray-200 pb-4'>
                                                    <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                        <div className="">
                                                            <label htmlFor="total_leave" className='label'>PF Account Number:</label>
                                                            <input type="text" name='pf_account_number' value={employeDetail.statutory.epf.pf_account_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='AA/AAA/0000000/000/0000000' />
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="total_leave" className='label'>UAN:</label>
                                                            <input type="text" name='pf_uan_number' value={employeDetail.statutory.epf.pf_uan_number} onChange={(e) => handleChangeStatutoryForm(e, 'epf')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                        <div className=" mt-4">
                                            <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                <input type="checkbox" name='isEsi' id='isEsi' checked={employeDetail.statutory?.esi?.isEsi} onChange={handleChangeStatutoryComponent} />
                                                <label htmlFor='isEsi' className=''>Employees' State Insurance</label>
                                            </label>
                                            {employeDetail?.statutory?.esi?.isEsi &&
                                                <div className='border-b border-gray-200 pb-4'>
                                                    <div className=" grid grid-cols-2 gap-4 leaveAllot-section">
                                                        <div className="">
                                                            <label htmlFor="total_leave" className='label'>ESI Insurance Number:</label>
                                                            <input type="text" name='esi_number' value={employeDetail.statutory.esi.esi_number} onChange={(e) => handleChangeStatutoryForm(e, 'esi')} className='input disabled:cursor-not-allowed' placeholder='000000000000' />
                                                        </div>
                                                    </div>
                                                    <label htmlFor="" className=' space-x-2 mt-4 block'>
                                                        <small className=' text-gray-500'>Note: ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000 </small>
                                                    </label>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className=" grid grid-cols-1 gap-4 leaveAllot-section">
                                        <div className=" mt-4">
                                            <label htmlFor="" className=' space-x-2 cursor-pointer'>
                                                <input type="checkbox" name='isPt' id='isPt' checked={employeDetail.statutory?.isPt} onChange={(e) => handleChangeStatutoryCheckbox(e, 'isPt')} />
                                                <label htmlFor='isPt' className=''>Professional Tax</label>
                                            </label>
                                            <label htmlFor="" className=' space-x-2 block'>
                                                <small className=' text-gray-500'>
                                                    Note: Professional Tax deductions <br />
                                                    Male employee if salary between &#8377; 7,500 - &#8377; 10,000
                                                    - (&#8377;175) will be deducted if salary greater then &#8377; 10,000 - (&#8377;200) will be deducted <br />
                                                    Female employee if salary between &#8377; 7,500 - &#8377; 25,000
                                                    - (&#8377;0) will be deducted if salary greater then &#8377; 25,001 - (&#8377;200) will be deducted <br />
                                                </small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" text-center my-8">
                            <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color9)] text-white py-3 px-20 rounded-full'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>}
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is Sucessfully Stored </p>
                    <div className=" mt-12">
                        <Link to={"/employe-details"} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</Link>
                    </div>
                </div>
            </Modals>
            <Modal open={regeneratePasswordModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Reset Password</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={() => { setRegeneratePasswordModal(false); setCopied(false) }}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body py-4 max-h-96 overflow-auto">
                            <form onSubmit={handleSetNewPassword}>
                                <div className=' grid grid-cols-2 gap-4 items-end'>
                                    <div className="">
                                        <label htmlFor="gross_salary" className='label'>Password</label>
                                        <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={newPassword} />
                                    </div>
                                    <CopyToClipboard onCopy={() => { setCopied(true) }} text={"New password: " + newPassword}>
                                        <button type='button' className='px-5 py-2 h-[2.6rem] w-max rounded bg-[color:var(--color9)] text-white mt-4'>{copied ? 'Copied' : 'Copy'} </button>
                                    </CopyToClipboard>
                                </div>
                                <div className="">
                                    <label htmlFor="gross_salary" className='label'></label>
                                    {newPassword ? ' ' : <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Generate Password</button>}
                                </div>
                                {isResettingPassword ? <div className='w-6 h-6 mx-0 mt-5'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : newPassword && <div className='mt-5'>
                                    <button className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Save</button>
                                </div>}
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <DialogMessage open={empCreateError} type={"Error"} message={empCreateError === "Required Fields" ? "Some fields are missing" : empCreateError} handleClose={() => setEmpCreateError('')} />
        </Layout>
    )
}

export default AddEmployeeDetails